<template>
  <nav v-if="totalPages > 1" class="d-flex justify-content-center">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <button class="page-link" @click="previousPage">
          <i class="bi bi-chevron-left"></i>
        </button>
      </li>
      <li
        v-for="page in pageList"
        :key="page"
        class="page-item"
        :class="{
          active: currentPage === page,
          'disabled-page': page === '...',
        }"
      >
        <button class="page-link" @click="goToPage(page)">{{ page }}</button>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <button class="page-link" @click="nextPage">
          <i class="bi bi-chevron-right"></i>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "DynamicPagination",
  props: {
    totalResults: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalResults / this.limit);
    },
    pageList() {
      const pageNumbers = [];
      const maxVisiblePages = 1;
      const firstPage = 1;
      const lastPage = this.totalPages;

      if (this.totalPages <= maxVisiblePages * 2 + 1) {
        pageNumbers.push(
          ...Array.from({ length: this.totalPages }, (_, i) => i + 1)
        );
      } else {
        const currentPage = this.currentPage;

        if (currentPage <= maxVisiblePages) {
          pageNumbers.push(
            ...Array.from({ length: maxVisiblePages * 2 + 1 }, (_, i) => i + 1)
          );
        } else if (currentPage >= lastPage - maxVisiblePages) {
          pageNumbers.push(
            ...Array.from(
              { length: maxVisiblePages * 2 + 1 },
              (_, i) => lastPage - maxVisiblePages * 2 + i
            )
          );
        } else {
          pageNumbers.push(
            ...Array.from(
              { length: maxVisiblePages * 2 + 1 },
              (_, i) => currentPage - maxVisiblePages + i
            )
          );
        }
        if (
          pageNumbers.includes(lastPage - 2) &&
          !pageNumbers.includes(lastPage - 1)
        ) {
          pageNumbers.splice(pageNumbers.length - 0, 0, "...");
        }
      }
      if (!pageNumbers.includes(firstPage)) {
        pageNumbers.unshift(firstPage);
      }
      if (!pageNumbers.includes(lastPage)) {
        pageNumbers.push(lastPage);
      }

      return pageNumbers;
    },
  },
  methods: {
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.emitPageChange(page);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.emitPageChange(this.currentPage + 1);
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.emitPageChange(this.currentPage - 1);
      }
    },
    emitPageChange(page) {
      this.$emit("page-change", page);
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination__button {
  padding: 5px 10px;
  margin: 0 5px;
}

.pagination__text {
  margin: 0 10px;
}
</style>
